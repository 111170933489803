<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { paymentService } from '../../../services/payment.service';

import {
    paymentServiceMethods,
    notificationMethods
} from "@/state/helpers";

import {
  required,
} from "vuelidate/lib/validators";

export default {
    components: {

        FormWizard,
        TabContent
    },
    data() {
        return {
            title: "Checkout",
            items: [
                {
                text: "Smart Exchange"
                },
                {
                text: "Send Money",
                active: true
                }
            ],
            form: {
                transactionReference: ""
            },
            submitted: false,

            transactionFound: true,
            transactionDetailsFound: false,
            authorisationFailed:false,
            notificationType:"",
            noticationIcon: "",
            transactionDetail: {},
            responseMessage: ""
        };
    },

    validations: {
        form: {
            transactionReference: { required },
        },
    },

    computed: {
        notification() {
        return this.$store ? this.$store.state.notification : null;
        }
    },

    methods: {
    ...paymentServiceMethods,
    ...notificationMethods,

        async searchTransaction() {
            this.submitted = true;
            this.$v.$touch();
            
            if (this.$v.$invalid) {
                 this.submitted = false;
                return;
            } else {
                const {form} = this;
                var status = false;
                try {
                    await paymentService.getTransactionByReference(form.transactionReference).then(response=>{
                        if(response.responseBody!==null){
                            this.transactionFound = true;
                            this.transactionDetailsFound = true;
                            this.transactionDetail = response.responseBody;
                            status = true;
                             this.submitted = false;
                        }else{
                            this.transactionFound = false;
                            status = false;
                             this.submitted = false;
                        }
                    });
                    return status
                } catch (error) {
                     this.submitted = false;
                    console.log(error);
                    return false;
                }
            }
        },

        async authorizeTransaction() {
            const requestBody =   {
                "operation" : "Update Status",
                "accessToken" : localStorage.getItem('access_token'),
                "requestBody" :
                {
                    "shopId" : JSON.parse(localStorage.getItem('user')).shopId,
                    "transactionId" : this.transactionDetail.transactionId,
                    "transactionType" : "remittance-customer-withdrawal",
                    "agentId" : JSON.parse(localStorage.getItem('user')).id,
                }
            };
            var status = false;
            try {
                await paymentService.authorizeTransaction(requestBody).then(response=>{
                    if(response.status==="SUCCESS"){
                        this.responseMessage = response.message;
                        status = true;
                    }else{
                        this.notificationType = "danger";
                        this.noticationIcon = "mdi-block-helper";
                        this.responseMessage = response.message;
                        this.authorisationFailed = true;
                        status = false;
                    }
                });
                return status
            } catch (error) {
                this.notificationType = "danger";
                this.noticationIcon = "mdi-block-helper";
                console.log(error);
                return false;
            }
        },

        onComplete(){
            // alert('transaction success');
        }
    }
};
</script>



<template>

        <div class="card">
            <div class="card-header">
                 <h5 class="mb-0 text-black">Cash Collection</h5>
            </div>
            <div class="card-body">
                <b-alert
                    :variant="notificationType" 
                    class="mt-3"
                    v-if="notification.message"
                    show
                    dismissible
                >
                    <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
                </b-alert>
                <form-wizard color="#b1945e" @on-complete="onComplete">
                <tab-content title="Find Transaction"  :before-change="searchTransaction"  icon="fas fa-search">
                    <div class="tab-pane" id="transaction-reference">
                        
                        <h5 class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="!transactionFound">Transaction Not Found</h5>
                        <form >
                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group mb-4 mb-lg-0">
                                        <label for="validationCustom01">Transaction reference</label>
                                        <input
                                            id="validationCustom01"
                                            v-model="form.transactionReference"
                                            type="text"
                                            class="form-control"
                                            placeholder="Enter transaction reference"
                                            :class="{ 'is-invalid': submitted && $v.form.transactionReference.$error }"
                                        />
                                        <div v-if="submitted && $v.form.transactionReference.$error" class="invalid-feedback">
                                            <span v-if="!$v.form.transactionReference.required">Please enter the transaction reference.</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                </tab-content>
                <tab-content title="Transaction Details" :before-change="authorizeTransaction" icon="fas fa-info" >
                    <div class="tab-pane" id="trans-det">
                    
                    <p class="card-title-desc">Verify if the transaction details are correct</p>
                     <h5 class="card-title badge-soft-danger pb-2 pt-2 pl-2" v-if="authorisationFailed">{{responseMessage}}</h5>
                    <div class="row" v-if="transactionDetailsFound">

                        <div class="col-lg-6 col-sm-6">
                        <div class="card border rounded active transaction-details">
                            <div class="card-body">
                            <h5 class="float-right ml-1">{{this.transactionDetail.transactionReference}} </h5>
                            <h5 class="font-size-16 mb-4">Receiver Details</h5>

                            <h5 class="font-size-16">Name: {{this.transactionDetail.receiverFirstName + ' ' + this.transactionDetail.receiverLastName}} </h5>
                            <p class="font-size-16 mb-0">Address: <b class="font-size-15">The {{this.transactionDetail.receiverPhysicalAddress}}</b></p>
                            <p class="font-size-16 mb-0">National ID: <b>{{this.transactionDetail.receiverNationalId}}</b></p>
                            <p class="font-size-16 mb-0"> Phone number: <b>{{this.transactionDetail.receiverMobileNumber}}</b></p>
                            <p class="font-size-16 mb-0">Will receive $<b>{{this.transactionDetail.receivableAmount}}</b></p>
                            </div>
                        </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                        <div class="card border rounded active transaction-details">
                            <div class="card-body">
                            <h5 class="float-right ml-1">{{this.transactionDetail.transactionReference}} </h5>
                            <h5 class="font-size-16 mb-4">Sender Details</h5>

                            <h5 class="font-size-16">Full Name: <b>{{this.transactionDetail.senderDetails.lastName +' '+this.transactionDetail.senderDetails.firstName }}</b> </h5>

                            <p class="font-size-16 mb-0">Purpose: <b>{{this.transactionDetail.transactionPurpose}}</b></p>
                            <p class="font-size-16 mb-0"> Amount Sent: <b>${{this.transactionDetail.amount}}</b></p>
                            
                            <p class="font-size-16 mb-0"> Transaction Fee: <b>${{this.transactionDetail.transactionFee}}</b></p>
                            <p class="font-size-16 mb-0"> Transaction Status: <b 
                            class="badge font-size-16"
                                :class="{'badge-soft-info': `${this.transactionDetail.remittanceTransactionStatus}` === 'deposited',
                                'badge-soft-success': `${this.transactionDetail.remittanceTransactionStatus}` === 'collected',
                                'badge-soft-warning': `${this.transactionDetail.remittanceTransactionStatus}` === 'pending'}"
                            >{{this.transactionDetail.remittanceTransactionStatus}}</b></p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </tab-content>
                <tab-content title="Completed" icon="fas fa-check">
                    <div class="tab-pane" id="payment-info" >
                        <h5 class="card-title badge-soft-success pb-2 pt-2 pl-2 font-size-17" ><b class="mb-4 ml-4">{{this.responseMessage}}</b></h5>
                    </div>
                </tab-content>
                </form-wizard>
            </div>
            </div>
        <!-- end row -->

</template>