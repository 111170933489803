<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Sendmoney from './sendmoney';
import Receivemoney from './receivemoney';
import ReverseMoney from './reversemoney';

/**
 * Products Component
 */
export default {
  page: {
    title: "Remittances",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ReverseMoney,
    Sendmoney,
    Receivemoney
  },
  data() {
    return {
      title: "REMITTANCES",
      items: [
        {
          text: "Smart Exchange"
        },
        {
          text: "Remittances",
          active: true
        }
      ],
      sliderPrice: 800,
      sendMoney: false,
      withdrawal: false,
      transactionReversal: false
    };
  },
    methods: {
        displayForms(element) {
            switch (element) {
                case "transactionReversal":
                    this.transactionReversal =true;
                    this.sendMoney = false;
                    this.withdrawal = false;
                    break;
                      
                case "sendMoney":
                    this.transactionReversal =false;
                    this.sendMoney = true;
                    this.withdrawal = false;
                    break;
                case "withdrawal":
                    this.transactionReversal =false;
                    this.sendMoney = false;
                    this.withdrawal = true;
                    break;

                default:
                    break;
            }
          },
    }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />


<div class="row">
  <div class="col-md-3">
      
      <div class="row">
          <div class="col-lg-11">
              <div class="card" id ="topCurve" style="backgroundColor:#268e9c"  >
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <h4 class="mb-0 text-white">Send Money</h4>
                      <p class="text-white font-size-14 mb-2 mt-2">Complete customer transaction</p>
                    </div>
                    <div class="text-white">
                      <img height=20 src="../../../assets/images/company_logo_white.png"/>
                    </div>
                  </div>
                    <div class="media mt-2 ">
                      <b-button variant="light"  class="media-body font-size-17" @click="displayForms('sendMoney')">
                        Send Money
                    </b-button>
                    </div>
                </div>
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-lg-11">
              <div class="card" id ="topCurve" style="backgroundColor:#b1945e" >
                <div class="card-body">
                  <div class="media">
                    <div class="media-body overflow-hidden">
                      <h4 class="mb-0 text-white">Withdraw Money</h4>
                      <p class="text-white font-size-14 mb-2 mt-2"> Collection</p>
                    </div>
                    <div class="text-white">
                      <i class="ri-exchange-dollar-line font-size-28 "></i>
                    </div>
                  </div>
                    <div class="media mt-2 ">
                      <b-button variant="light"  class="media-body font-size-17" @click="displayForms('withdrawal')">
                        Withraw Money
                    </b-button>
                    </div>
                </div>
              </div>
          </div>
      </div>
      <div class="row">
        <div class="col-lg-11">
          <div class="card generic-font-color" id ="topCurve" >
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <h4 class="mb-0 generic-font-color">Transaction Reversal </h4>
                  <p class="font-size-14 mb-2 mt-2">Reverse a transaction</p>
                </div>
                <div class="">
                  <i class="ri-file-paper-2-line font-size-28"></i>
                </div>
              </div>
                <div class="media mt-2 ">
                  <b-button variant="light"  class=" media-body font-size-17" @click="displayForms('transactionReversal')">
                    Transaction Reversal
                </b-button>
                </div>
            </div>
          </div>
        </div>
    </div>
  </div>

  <div class="col-md-8">
    <div class="row">
      <div class="col-lg-12"  v-if="sendMoney">
          <Sendmoney/>
      </div>
      <div class="col-lg-12"  v-if="withdrawal">
          <Receivemoney/>
      </div>
      <div class="col-lg-12"  v-if="transactionReversal">
        <ReverseMoney/>
      </div>
    </div>
  </div>
</div>
  </Layout>
</template>

