<script>

import { FormWizard, TabContent } from "vue-form-wizard";
import { paymentService } from '../../../services/payment.service';
import { shopService } from '../../../services/shop.service';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import {
    paymentServiceMethods,
    notificationMethods
} from "@/state/helpers";

import {
  required, numeric
} from "vuelidate/lib/validators";

const initForm =  {
                "senderId" : JSON.parse(localStorage.getItem('user')).id,
                "amount" : null,
                "transactionFee" : null,
                "receivableAmount" : null,
                "senderEmailAddress" : null,
                "senderFirstName" : null,
                "senderLastName" : null,
                "senderNationalId" : null,
                "senderMobileNumber" : null,
                "receiverFirstName" : null,
                "receiverLastName" : null,
                "receiverNationalId" : null,
                "receiverEmailAddress" : null,
                "receiverMobileNumber" : null,
                "transactionPurpose" : null,
                "customerType" : null,
                "receiverPhysicalAddress":null,
                "currencyType":"",
                "preferredPickUpShopName":null,
                "preferredPickUpShopId":null,
                "selectedShop":{}
            };


export default {
    components: {
        FormWizard,
        TabContent,
        VuePhoneNumberInput
    },
    data() {
        return {
            shopList:[],
            senderResults: {},
            amountNote:"",
            receiverResults: {},
            currencyList: [
                {
                    currencyType:"zwl",
                    currencyName: "ZIMBABWEAN DOLLAR"
                },
                {
                    currencyType:"usd",
                    currencyName: "USD"
                },
                {
                    currencyType:"zar",
                    currencyName: "RAND"
                },
                {
                    currencyType:"gbp",
                    currencyName: "BRITISH POUND"
                },
                {
                    currencyType:"eur",
                    currencyName: "EURO"
                }
            ],
            dataForm: {
                "senderId" : JSON.parse(localStorage.getItem('user')).id,
                "amount" : null,
                "transactionFee" : null,
                "receivableAmount" : null,
                "senderEmailAddress" : null,
                "senderFirstName" : null,
                "senderLastName" : null,
                "senderNationalId" : null,
                "senderMobileNumber" : null,
                "receiverFirstName" : null,
                "receiverLastName" : null,
                "receiverNationalId" : null,
                "receiverEmailAddress" : null,
                "receiverMobileNumber" : null,
                "transactionPurpose" : null,
                "customerType" : null,
                "receiverPhysicalAddress":"",
                "currencyType":"",
                "preferredPickUpShopName":null,
                "preferredPickUpShopId":null,
                "selectedShop":{}
            },
            submitted: false,
            invalidTransaction:false,
            notificationType:null,
            noticationIcon: null,
            finalIcon: "fas fa-spinner",
            
            transactionDetail: {},
            responseMessage: null,
            transactionCheckMessage:null,
            submitform: false,
            submit: false,
            responseBack:false

        };
    },

    created() {
    this.loadAllShops()
  },

    validations: {
        dataForm: {
            "receivableAmount" : {required, numeric},
            "senderFirstName" : {required},
            "senderLastName" : {required},
            "senderNationalId" : {required},
            "senderMobileNumber" : {required},
            "receiverFirstName" : {required},
            "receiverLastName" : {required},
            "receiverNationalId" : {required},
            "receiverMobileNumber" : {required},
            "transactionPurpose" : {required},
            "currencyType":{required},
            "selectedShop":{required}
        },
    },

    computed: {
        notification() {
        return this.$store ? this.$store.state.notification : null;
        }
    },

    methods: {
    ...paymentServiceMethods,
    ...notificationMethods,

    onUpdateSenderNumber(payload) {
        this.senderResults = payload.formattedNumber.replace("+","");
    },

    onUpdateReceiverNumber(payload) {
        this.receiverResults = payload.formattedNumber.replace("+","")
    },

    onChangeAmount () {
        const {dataForm} = this;

        var fee = Math.ceil(JSON.parse(localStorage.getItem('businessConditions')).percentageFeesCharged * dataForm.receivableAmount);
        var sumChargeInclusive = (1*dataForm.receivableAmount + 1*fee);

        dataForm.transactionFee = fee;
        this.amountNote = "The receiver will collect "+dataForm.currencyType.toUpperCase()+" "+(dataForm.receivableAmount)+". You will have to deposit "+dataForm.currencyType.toUpperCase()+" "+sumChargeInclusive

        if(1*dataForm.receivableAmount > (JSON.parse(localStorage.getItem('businessConditions')).maximumTransactionAmount)){
            this.transactionCheckMessage = "Invalid Amount. Maximum transaction is $"+JSON.parse(localStorage.getItem('businessConditions')).maximumTransactionAmount
            this.invalidTransaction = true;
            return false
        }else{
            this.invalidTransaction = false;
        }

    },

    async loadAllShops() {
          try {
              await shopService.getAllShops().then(response=>{
                  if(response.responseBody.length>0){
                      this.shopList = response.responseBody;
                  }
              });
          } catch (error) {
              console.log(error);
          }
      },


      formatPayload(){
    
        const {dataForm} = this;

        var fee =JSON.parse(localStorage.getItem('businessConditions')).percentageFeesCharged * dataForm.receivableAmount
        dataForm.transactionFee = Math.ceil(fee);

        if(1*dataForm.receivableAmount > (JSON.parse(localStorage.getItem('businessConditions')).maximumTransactionAmount)){
            this.transactionCheckMessage = "Invalid Amount. Maximum transaction is $"+JSON.parse(localStorage.getItem('businessConditions')).maximumTransactionAmount
            this.invalidTransaction = true;
            return false
        }else{
            this.invalidTransaction = false;
        }


        dataForm.amount = 1*dataForm.receivableAmount  + 1*dataForm.transactionFee;
        dataForm.customerType = "guest";
        dataForm.preferredPickUpShopName = dataForm.selectedShop.name;
        dataForm.preferredPickUpShopId = dataForm.selectedShop.id;
        

        return true;
    },

        formSubmit() {
            // this.submitted = true;
            this.$v.$touch();

            if (this.$v.$invalid) {
                //this.submitted = false;
                return;
            } else {
                const {dataForm} = this;

                dataForm.senderMobileNumber = this.senderResults;
                dataForm.receiverMobileNumber = this.receiverResults;

                const shopForm = {
                        "operation" : "sendMoney",
                        "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                        "requestBody" : dataForm
                };
                
                paymentService.initiateTransaction(shopForm).then(result=>{
                    this.responseBack = true;
                    if(result.status=="SUCCESS"){
                        this.finalIcon = "fas fa-check";
                        this.responseMessage = result.message;
                        this.notificationType = "success";
                        this.noticationIcon = "mdi-check-all";
                        this.submitted = false;
                        this.dataForm = Object.assign({}, initForm);
                    } else {
                    this.responseMessage = result.message;
                    this.notificationType = "danger";
                    this.noticationIcon = "mdi-block-helper";
                    }
                });
            }
        },

    }
};
</script>

<template>

            <div >
                <div class="card-header">
                    <h5 class="mb-0 text-black">Initiate Transaction</h5>
                </div>
                <div class="card-body">

                    <form-wizard color="#268e9c"  @on-complete="formSubmit" finish-button-text="Submit Transaction" >
                            <tab-content title="Sender Details"  icon="fas fa-user" :before-change="formatPayload">
                                <b-alert
                                        variant="danger" 
                                        class="mt-3"
                                        v-if="invalidTransaction"
                                        show
                                    >
                                        <i :class="'mdi mdi-cancel'+ noticationIcon + ' mr-2'"></i>{{this.transactionCheckMessage}}
                                </b-alert>

                                <b-alert
                                    variant="info" 
                                    class="mt-3"
                                    v-if="!invalidTransaction"
                                    show
                                >
                                    {{this.amountNote}}
                                </b-alert>

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label >Sender ID Number</label>
                                            <input
                                                
                                                v-model="dataForm.senderNationalId"
                                                type="text"
                                                class="form-control"
                                                placeholder="National ID"
                                                :class="{ 'is-invalid': $v.dataForm.senderNationalId.$error }"
                                            />
                                            <div v-if="$v.dataForm.senderNationalId.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.senderNationalId.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Sender First Name</label>
                                            <input
                                                v-model="dataForm.senderFirstName"
                                                type="text"
                                                class="form-control"
                                                placeholder="First Name"
                                                :class="{ 'is-invalid': $v.dataForm.senderFirstName.$error }"
                                            />
                                            <div v-if="$v.dataForm.senderFirstName.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.senderFirstName.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label >Sender Last Name</label>
                                            <input
                                                v-model="dataForm.senderLastName"
                                                type="text"
                                                class="form-control"
                                                placeholder="Last Name"
                                                :class="{ 'is-invalid': $v.dataForm.senderLastName.$error }"
                                            />
                                            <div v-if="$v.dataForm.senderLastName.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.senderLastName.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-7">
                                        <div class="form-group">
                                            <label >Sender Mobile Number</label>
                                            <div>
                                                <VuePhoneNumberInput
                                                    default-country-code="ZW"
                                                    required
                                                    v-model="dataForm.senderMobileNumber"
                                                    @update="onUpdateSenderNumber"
                                                    :class="{ 'is-invalid': $v.dataForm.senderMobileNumber.$error }"
                                                />
                                                <div v-if="$v.dataForm.senderMobileNumber.$error" class="invalid-feedback">
                                                    <span v-if="!$v.dataForm.senderMobileNumber.required">This value is required.</span>
                                                    <span v-if="!$v.dataForm.senderMobileNumber.numeric">This value should be a valid phone number.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group">
                                            <label >Sender Email address</label>
                                            <div>
                                                <input
                                                    id="validationCustom03"
                                                    v-model="dataForm.senderEmailAddress"
                                                    type="email"
                                                    class="form-control"
                                                    placeholder="Enter email address "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Currency</label>
                                            <select class="form-control" v-model="dataForm.currencyType" :class="{ 'is-invalid': $v.dataForm.currencyType.$error }">
                                                <option v-for="(currency, index) in currencyList" :value="currency.currencyType" :key="index">{{currency.currencyName}}</option>
                                            </select>
                                            <div v-if="$v.dataForm.currencyType.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.currencyType.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label for="validationCustom01">Receivable Amount</label>
                                            <input
                                                
                                                v-model="dataForm.receivableAmount"
                                                type="number"
                                                class="form-control"
                                                @change="onChangeAmount"
                                                placeholder="Amount "
                                                :class="{ 'is-invalid': $v.dataForm.receivableAmount.$error }"
                                            />
                                            <div v-if="$v.dataForm.receivableAmount.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.receivableAmount.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Preffered Pickup Branch </label>
                                            <select class="form-control" v-model="dataForm.selectedShop" :class="{ 'is-invalid': $v.dataForm.selectedShop.$error }">
                                                <option v-for="(shop, index) in shopList" :value="shop" :key="index">{{shop.name}}</option>
                                            </select>
                                            <div v-if="$v.dataForm.selectedShop.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.selectedShop.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-md-8">
                                        <div class="form-group">
                                            <label >Transaction Purpose</label>
                                            <div>
                                                <input
                                                    
                                                    v-model="dataForm.transactionPurpose"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Transaction Purpose"
                                                    :class="{ 'is-invalid': $v.dataForm.transactionPurpose.$error }"
                                                />
                                                <div v-if="$v.dataForm.transactionPurpose.$error" class="invalid-feedback">
                                                    <span v-if="!$v.dataForm.transactionPurpose.required">This value is required.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </tab-content>

                            <tab-content title="Receiver Details"  icon="fas fa-user" >

                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label >Receiver ID Number</label>
                                            <input
                                                
                                                v-model="dataForm.receiverNationalId"
                                                type="text"
                                                class="form-control"
                                                placeholder="National ID"
                                                :class="{ 'is-invalid': $v.dataForm.receiverNationalId.$error }"
                                            />
                                            <div v-if="$v.dataForm.receiverNationalId.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.receiverNationalId.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>Receiver First Name</label>
                                            <input
                                                v-model="dataForm.receiverFirstName"
                                                type="text"
                                                class="form-control"
                                                placeholder="First Name"
                                                :class="{ 'is-invalid': $v.dataForm.receiverFirstName.$error }"
                                            />
                                            <div v-if="$v.dataForm.receiverFirstName.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.receiverFirstName.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label >Receiver Last Name</label>
                                            <input
                                                v-model="dataForm.receiverLastName"
                                                type="text"
                                                class="form-control"
                                                placeholder="Last Name"
                                                :class="{ 'is-invalid': $v.dataForm.receiverLastName.$error }"
                                            />
                                            <div v-if="$v.dataForm.receiverLastName.$error" class="invalid-feedback">
                                                <span v-if="!$v.dataForm.receiverLastName.required">This value is required.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label >Receiver Mobile Number</label>
                                            <div>
                                                <VuePhoneNumberInput 
                                                    default-country-code="ZW"
                                                    required
                                                    :loader="hasLoaderActive"
                                                    @update="onUpdateReceiverNumber"
                                                    v-model="dataForm.receiverMobileNumber"
                                                    :class="{ 'is-invalid': $v.dataForm.receiverMobileNumber.$error }"
                                                />
                                                <!-- <input
                                                    
                                                    v-model="dataForm.receiverMobileNumber"
                                                    type="number"
                                                    class="form-control"
                                                    placeholder="Enter phone number"
                                                    :class="{ 'is-invalid': $v.dataForm.receiverMobileNumber.$error }"
                                                /> -->
                                                
                                                <div v-if="$v.dataForm.receiverMobileNumber.$error" class="invalid-feedback">
                                                    <span v-if="!$v.dataForm.receiverMobileNumber.required">This value is required.</span>
                                                    <span v-if="!$v.dataForm.receiverMobileNumber.numeric">This value should be a valid phone number.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label >Receiver Email address</label>
                                            <div>
                                                <input
                                                    id="validationCustom03"
                                                    v-model="dataForm.receiverEmailAddress"
                                                    type="email"
                                                    class="form-control"
                                                    placeholder="Enter email address "
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label >Physical Address</label>
                                            <div>
                                                <input
                                                    
                                                    v-model="dataForm.receiverPhysicalAddress"
                                                    type="text"
                                                    class="form-control"
                                                    placeholder="Receiver Address"
                                                    />
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>

                            <tab-content title="Submit Transaction" :icon="finalIcon" >
                                <div class="tab-pane" id="payment-info" >
                                    <b-alert
                                        :variant="notificationType" 
                                        class="mt-3"
                                        v-if="responseBack"
                                        show
                                        dismissible
                                    >
                                        <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{responseMessage}}
                                        <!-- <h5 class="card-title badge-soft-success mb-4 pb-2 pt-2 pl-2 font-size-17" ><b class="mb-4 ml-4">{{this.responseMessage}}</b></h5> -->
                                    </b-alert>
                                    <div class="spinner" v-if="submitted && !responseBack">
                                        <i class="ri-loader-line spin-icon"></i>
                                    </div>
                                    <div v-if="!submitted && !responseBack">
                                        <p class="card-title-desc">Verify if the transaction details are correct</p>
                                        <div class="tab-pane row" id="trans-det">
                                            
                                            <div class="col-md-6">
                                                <div class="card border rounded active transaction-details">
                                                    <div class="card-body">
                                                        <h5 class="font-size-16 mb-2">Sender Details</h5>

                                                        <h5 class="font-size-16">Full Name: <b>{{ dataForm.senderFirstName +" "+dataForm.senderLastName}}</b> </h5>
                                                        <p class="font-size-16 mb-0">ID Number: <b>{{dataForm.senderNationalId}}</b></p>
                                                        <p class="font-size-16 mb-0">Phone Number: <b>{{dataForm.senderMobileNumber}}</b></p>
                                                        <hr/>
                                                        <p class="font-size-16 mb-0"> Amount Deposited: <b>{{dataForm.currencyType.toUpperCase()+" "+dataForm.amount}}</b></p>
                                                        <p class="font-size-16 mb-0">Purpose: <b>{{dataForm.transactionPurpose}}</b></p>
                                                        <p class="font-size-16 mb-0"> Transaction Fee: <b>{{dataForm.currencyType.toUpperCase()+" "+dataForm.transactionFee}}</b></p>
                                                        <p class="font-size-16 mb-0"> Pickup Branch: <b>{{dataForm.preferredPickUpShopName}}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="card border rounded active transaction-details">
                                                    <div class="card-body">
                                                        <h5 class="font-size-16 mb-2">Receiver Details</h5>

                                                        <h5 class="font-size-16">Name: <b>{{dataForm.receiverFirstName + ' ' + dataForm.receiverLastName}}</b> </h5>
                                                        <p class="font-size-16 mb-0">ID Number: <b>{{dataForm.receiverNationalId}}</b></p>
                                                        <p class="font-size-16 mb-0"> Phone number: <b>{{dataForm.receiverMobileNumber}}</b></p>
                                                        <p class="font-size-16 mb-0"> Email: <b>{{dataForm.receiverEmailAddress}}</b></p>
                                                        <p class="font-size-16 mb-0">Address: <b class="font-size-14">{{dataForm.receiverPhysicalAddress}}</b></p>
                                                        <hr/>
                                                        <p class="font-size-16 mb-0">Will receive: <b>{{dataForm.currencyType.toUpperCase()+" "+dataForm.receivableAmount}}</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>

                                <!-- <div class="row">
                                    <div class="col-md-4" v-if="!submitted">
                                        <button class="btn btn-primary" type="submit">Initiate Transaction</button>
                                    </div>
                                    <div class="col-md-6">
                                        
                                    </div>
                                </div> -->
                        <!-- </form> -->
                    </form-wizard>
                </div>
            </div>  

</template>